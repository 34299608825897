import Image from "next/image";

type TDialogImage = {
  src: string | undefined;
  alt: string | undefined;
};

// TODO replace default image with an image having aspect ratio of 4:3 and having a contentin it with ratio 4:2
const fallbackSrc =
  "https://cdn.glamgirls.ai/character/d6ea6430f53973d5b0c8ae454585b4bf41c7fa141b37f7ab96f6d9386a406819/avatar.webp";
const fallbackAlt = "Image of AI character";

export default function DialogImage({ src, alt }: TDialogImage) {
  return (
    <div className="aspect-[4/3] sm:aspect-[4/2] overflow-hidden relative rounded-t-xl bg-base-200">
      <Image
        src={src ? src : fallbackSrc}
        className="absolute inset-0 top-[-25px] bottom-0 h-auto w-full object-cover object-top z-0"
        alt={alt ? alt : fallbackAlt}
        fill
      />
    </div>
  );
}
