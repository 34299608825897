"use client";

import { useCallback } from "react";
import { Button } from "../ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "../ui/dialog";
import { ModalLayout } from "./modal-layout";

type PropTypes = {
  open?: boolean | undefined;
  onOpen?: (open: boolean) => void | undefined;
  handleSubmit: () => void;
};

export function AgeConfirmationModal({
  open = undefined,
  onOpen = undefined,
  handleSubmit,
}: PropTypes) {
  const close = useCallback(() => {
    if (onOpen) onOpen(false);
  }, [onOpen]);

  return (
    <ModalLayout open={open} onOpen={onOpen}>
      <DialogContent className="p-4">
        <DialogTitle>Confirm your age</DialogTitle>
        <DialogDescription>
          You may encounter sensitive or adult content that’s not for everyone.
          To view it, confirm your age.
        </DialogDescription>

        <DialogFooter>
          <Button
            variant={"neutral"}
            className="max-[360px]:w-full"
            onClick={() => close()}
          >
            Go back
          </Button>
          <Button
            className="max-[360px]:w-full"
            onClick={() => {
              handleSubmit();
              close();
            }}
          >
            I&apos;m over 18
          </Button>
        </DialogFooter>
      </DialogContent>
    </ModalLayout>
  );
}
