import { Lock } from "lucide-react";
import Link from "next/link";
import { useCallback } from "react";
import { Button } from "../ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "../ui/dialog";
import DialogFooterInfo from "./dialog-footer-info";
import DialogImage from "./dialog-image";
import { ModalLayout } from "./modal-layout";

type PropTypes = {
  open?: boolean | undefined;
  onOpen?: (open: boolean) => void | undefined;
};

export function SubscribeModal({
  open = undefined,
  onOpen = undefined,
}: PropTypes) {
  const close = useCallback(() => {
    if (onOpen) onOpen(false);
  }, [onOpen]);

  return (
    <ModalLayout open={open} onOpen={onOpen}>
      <DialogContent>
        <DialogImage
          src="https://cdn.glamgirls.ai/character/d6ea6430f53973d5b0c8ae454585b4bf41c7fa141b37f7ab96f6d9386a406819/avatar.webp"
          alt="Image of AI character"
        />

        <div className="p-4">
          <DialogTitle>Premium content</DialogTitle>
          <DialogDescription>
            You found a premium content! Subscribe to get access to it and many
            more... 😉
          </DialogDescription>

          <DialogFooter className="mb-4">
            <Link href="/subscribe" className="w-full">
              <Button variant="default" className="w-full" onClick={close}>
                <span className="">Claim early-bird discount</span>
              </Button>
            </Link>
          </DialogFooter>

          <DialogFooterInfo icon={Lock}>
            Click to Pay Securely with LemonSqueezy.
          </DialogFooterInfo>
        </div>
      </DialogContent>
    </ModalLayout>
  );
}
