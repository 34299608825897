import { Dialog } from "@/components/ui/dialog";

type PropTypes = {
  children?: React.ReactNode;
  open?: boolean | undefined;
  onOpen?: (open: boolean) => void | undefined;
};

export const ModalLayout = ({ children, open, onOpen }: PropTypes) => {
  if (open === undefined && onOpen === undefined) {
    return <Dialog>{children}</Dialog>;
  }

  if (open !== undefined && onOpen !== undefined) {
    return (
      <Dialog open={open} onOpenChange={onOpen}>
        {children}
      </Dialog>
    );
  }

  return null;
};
