import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { SignInButton } from "@clerk/nextjs";
import { LogIn } from "lucide-react";
import { Button } from "../ui/button";
import DialogImage from "./dialog-image";
import { ModalLayout } from "./modal-layout";

type PropTypes = {
  open?: boolean | undefined;
  onOpen?: (open: boolean) => void | undefined;
  modalProps: any;
};

export default function ChatLoginModal({
  open = undefined,
  onOpen = undefined,
  modalProps,
}: PropTypes) {
  const { imageSrc, imageAlt } = modalProps;

  return (
    <ModalLayout open={open} onOpen={onOpen}>
      <DialogContent>
        <DialogImage src={imageSrc} alt={imageAlt} />

        <div className="p-4">
          <DialogTitle>Wanna talk with me?</DialogTitle>
          <DialogDescription>
            Get started by signing in. Quick and cost-free – just a minute!
          </DialogDescription>

          <DialogFooter>
            <SignInButton>
              <DialogTrigger asChild>
                <Button className="w-full gap-2">
                  <LogIn className="size-4" />
                  <span className="font-medium">Sign In</span>
                </Button>
              </DialogTrigger>
            </SignInButton>
          </DialogFooter>
        </div>
      </DialogContent>
    </ModalLayout>
  );
}
