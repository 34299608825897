import { Info, LucideIcon } from "lucide-react";
import { ReactNode } from "react";

interface IDialogFooterInfo {
  icon?: LucideIcon;
  children: ReactNode;
}

export default function DialogFooterInfo({
  icon,
  children,
}: IDialogFooterInfo) {
  const Icon = icon ? icon : Info;
  return (
    <p className="flex items-center justify-center gap-2 text-xs text-base-muted">
      {Icon && <Icon className="size-3.5" />}
      <span>{children}</span>
    </p>
  );
}
