"use client";

import { Modal, useModal } from "@/lib/slices/modal.slice";
import { useCallback } from "react";
import { AgeConfirmationModal } from "./age-confirmation-modal";
import ChatLoginModal from "./chat-login-modal";
import LogInModal from "./login-modal";
import OutOfCreditsModal from "./out-of-credits-modal";
import { SubscribeModal } from "./subscribe-modal";

export default function Modals() {
  const modals = useModal();

  const close = useCallback(() => {
    modals.setActiveModal(undefined);
  }, [modals]);

  switch (modals.activeModal) {
    case Modal.CREDITS_MODAL:
      return <OutOfCreditsModal open={true} onOpen={close} />;

    case Modal.LOGIN_MODAL:
      return <LogInModal open={true} onOpen={close} />;

    case Modal.CHAT_LOGIN_MODAL:
      return (
        <ChatLoginModal
          open={true}
          onOpen={close}
          modalProps={modals.modalProps}
        />
      );

    case Modal.SUBSCRIBE_MODAL:
      return <SubscribeModal open={true} onOpen={close} />;

    case Modal.AGE_CONFIRMATION:
      return (
        <AgeConfirmationModal
          open={true}
          onOpen={close}
          handleSubmit={modals.handleSubmit!}
        />
      );

    default:
      return null;
  }
}
