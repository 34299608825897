import { SignInButton } from "@clerk/nextjs";
import { Button } from "../ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "../ui/dialog";
import DialogFooterInfo from "./dialog-footer-info";
import DialogImage from "./dialog-image";
import { ModalLayout } from "./modal-layout";

type PropTypes = {
  open?: boolean | undefined;
  onOpen?: (open: boolean) => void | undefined;
};

export default function LogInModal({
  open = undefined,
  onOpen = undefined,
}: PropTypes) {
  return (
    <ModalLayout open={open} onOpen={onOpen}>
      <DialogContent>
        <DialogImage
          src="https://cdn.glamgirls.ai/character/d6ea6430f53973d5b0c8ae454585b4bf41c7fa141b37f7ab96f6d9386a406819/avatar.webp"
          alt="Image of AI character"
        />

        <div className="p-4">
          <DialogTitle>Members only!</DialogTitle>
          <DialogDescription>
            Please login in order to use this feature
          </DialogDescription>

          <DialogFooter className="mb-4">
            <SignInButton>
              <Button className="w-full">Login</Button>
            </SignInButton>
          </DialogFooter>

          <DialogFooterInfo>Your choices will be saved</DialogFooterInfo>
        </div>
      </DialogContent>
    </ModalLayout>
  );
}
