"use client";

import { useEffect } from "react";
import gtm from "react-gtm-module";

export function Analytics() {
  useEffect(() => {
    gtm.initialize({ gtmId: `${process.env.NEXT_PUBLIC_GTM_KEY}` });

  }, []);

  return <div />;
}
